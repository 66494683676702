/* Модуль API с запросами к новостям на главной странице */

// eslint-disable
import API from './api'

const newsUrl = '/news/'

export const NewsAPI = {
    async getNews() {
        /**
         * Получение списка новостей
         */

        return API.get(`${newsUrl}`)
    },

    async getNewsList(page = 1) {
        /**
         * Получение списка новостей
         */

        return API.get(`/paginated_news/?page=${page}`)
    },

    async getNewById(newId) {
        /**
         * Получение новости по ее id
         */

        return API.get(`${newsUrl}${newId}/`)
    },

    async getNewsByCategoryId(categoryId) {
        /**
         * Получение списка новостей по категории.
         */

        return API.get(`${newsUrl}categories/${categoryId}/`)
    },
}
