/* Модуль API с запросами к разработкам */

// eslint-disable
import API from './api'

const productsUrl = '/products/'

export const ProductsAPI = {
    async getProducts(nextPage = '', search = '') {
        /**
         * Получение списка разработок
         */
        let searchParams = ''

        if (search) searchParams = `?title=${search}`
        if (nextPage) searchParams = `${nextPage}`

        return API.get(`${productsUrl}${searchParams}`)
    },

    async getProductById(productId) {
        /**
         * Получение разработки по ее id
         */

        return API.get(`${productsUrl}${productId}/`)
    },

    async getProductsByCategoryId(categoryId, nextPage = '', search = '') {
        /**
         * Получение списка разработок по категории.
         */

        console.log(search)

        return API.get(`${productsUrl}categories/${categoryId}/${nextPage}`)
    },

    async getRandomProducts(amount = 3) {
        /**
         * Получение списка рандомных разработок
         */

        return API.get(`/random_products/?amount=${amount}`)
    }
}
