/* Модуль API с запросами к категориям разработок */

// eslint-disable
import API from './api'

const productCategoriesUrl = '/product-categories/'

export const ProductCategoriesAPI = {
    async getProductCategories() {
        /**
         * Получение списка категорий разработок
         */

        return API.get(`${productCategoriesUrl}`)
    },

    async getProductCategoryById(categoryId) {
        /**
         * Получение категории разработки по ее id
         */

        return API.get(`${productCategoriesUrl}${categoryId}/`)
    },
}
