import { computed } from "vue";
import router from "@/router";
import { useStore } from 'vuex'

export default function() {
    const store = useStore()

    /* Computed */
    const isMenuVisible = computed(() => store.getters['base/isMenuVisible'])

    /* Methods */
    const toggleMenu = () => store.dispatch('base/toggleMenu')

    const goToPage = async (pageName, query = '') => {
        if (isMenuVisible.value) {
            await toggleMenu()
        }

        if (query) {
            await router.push({ name: pageName, query: { query }} )
        } else {
            await router.push({ name: pageName })
        }
    }

    const goToPageTop = () => {
        document.body.scrollIntoView({ behavior: "smooth" });
    }

    const redirectToItmo = () => {
        const itmoLink = 'https://itmo.ru/ru/'

        const url = document.createElement('a');
        url.href = itmoLink;
        url.target = '_blank';
        const event = new MouseEvent('click', {
            'view': window,
            'bubbles': false,
            'cancelable': true
        });
        url.dispatchEvent(event);
    }

    return {
        isMenuVisible,
        redirectToItmo,
        toggleMenu,
        goToPage,
        goToPageTop
    }
}
