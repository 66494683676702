// eslint-disable
import { NewsCategoriesAPI } from '@/api/newsCategories';

const state = {
    newsCategories: [], // Список категорий новостей
    selectedNewsCategory: null, // Выбранная категория новостей
}

const getters = {
    newsCategories: (s) => s.newsCategories,
    selectedNewsCategory: (s) => s.selectedNewsCategory,
}

const actions = {
    async getNewsCategories({ commit }) {
        const response = await NewsCategoriesAPI.getNewsCategories()

        commit('SET_NEWS_CATEGORIES', response.data)
    },

    async getNewsCategoryById({ commit }, categoryId) {
        const response = await NewsCategoriesAPI.getNewsCategoryById(categoryId)

        commit('SET_SELECTED_NEWS_CATEGORY', response.data)
    },
}

const mutations = {
    SET_NEWS_CATEGORIES(state, payload) {
        state.newsCategories = payload
    },

    SET_SELECTED_NEWS_CATEGORY(state, payload) {
        state.selectedNewsCategory = payload
    },
}

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
}
