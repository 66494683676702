// eslint-disable
import { ProductsAPI } from '@/api/products';

const state = {
    products: [], // Список разработок
    selectedProduct: null, // Выбранная разработка
    nextPage: '', // Следующая страница в пагинации
    selectedCategory: null, // Выбранная категория
    randomProducts: [] // Список рандомных разработок
}

const getters = {
    products: (s) => s.products,
    selectedProduct: (s) => s.selectedProduct,
    nextPage: (s) => s.nextPage,
    selectedCategory: (s) => s.selectedCategory,
    randomProducts: (s) => s.randomProducts
}

const actions = {
    async getProducts({ commit, getters }, payload = { searchedText: '', showMore: false }) {
        const { searchedText, showMore } = payload

        const nextPage = showMore ? getters.nextPage : ''

        const response = await ProductsAPI.getProducts(nextPage, searchedText)

        if (getters.nextPage && showMore) {
            commit('SET_PRODUCTS', [...getters.products, ...response.data.results])
        } else {
            commit('SET_PRODUCTS', response.data.results)
        }

        commit('SET_NEXT_PAGE', response.data.next)
    },

    async getProductById({ commit }, productId) {
        const response = await ProductsAPI.getProductById(productId)

        commit('SET_SELECTED_PRODUCT', response.data)

        return response.data
    },

    async getProductsByCategoryId({ commit, getters }, categoryId) {
        if (!getters.selectedCategory || getters.selectedCategory !== categoryId) {
            commit('SET_NEXT_PAGE', '')
        }

        const response = await ProductsAPI.getProductsByCategoryId(
            categoryId, getters.nextPage
        )

        commit('SET_SELECTED_CATEGORY', categoryId)

        if (getters.nextPage) {
            commit('SET_PRODUCTS', [...getters.products, ...response.data.results])
        } else {
            commit('SET_PRODUCTS', response.data.results)
        }

        commit('SET_NEXT_PAGE', response.data.next)
    },

    async getRandomProducts({ commit }, amount = 3) {
        const response = await ProductsAPI.getRandomProducts(amount)

        commit('SET_RANDOM_PRODUCTS', response.data)

        return response.data
    },

    selectCategory({ commit }, payload) {
        commit('SET_SELECTED_CATEGORY', payload)
        commit('SET_PRODUCTS', [])
        commit('SET_NEXT_PAGE', '')
    }
}

const mutations = {
    SET_PRODUCTS(state, payload) {
        state.products = payload
    },

    SET_SELECTED_PRODUCT(state, payload) {
        state.selectedProduct = payload
    },

    SET_NEXT_PAGE(state, payload) {
        if (payload) {
            const nextPageArg = new URL(payload).search
            state.nextPage = nextPageArg
        } else {
            state.nextPage = payload
        }
    },

    SET_SELECTED_CATEGORY(state, payload) {
        state.selectedCategory = payload
    },

    SET_RANDOM_PRODUCTS(state, payload) {
        state.randomProducts = payload
    }
}

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
}
