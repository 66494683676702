// eslint-disable
import { NewsAPI } from '@/api/news';

const state = {
    mainNews: [], // Список главных новостей
    subNews: [], // Список побочных новостей
    selectedNew: null, // Выбранная новость
    paginatedNews: { results: [], count: 0, next: '', previous: '' } // Пагинированные новости
}

const getters = {
    mainNews: (s) => s.mainNews,
    subNews: (s) => s.subNews,
    selectedNew: (s) => s.selectedNew,
    paginatedNews: (s) => s.paginatedNews
}

const actions = {
    async getNews({ commit }) {
        const response = await NewsAPI.getNews()

        const mainNews = response.data//.filter(news => news.category.id === 1)

        const subNews = response.data.filter(news => news.category.id === 2)

        commit('SET_MAIN_NEWS', mainNews)
        commit('SET_SUB_NEWS', subNews)
    },

    async getPaginatedNews({ commit }, page = 1) {
        const response = await NewsAPI.getNewsList(page)

        commit('SET_PAGINATED_NEWS', response.data)
    },

    async getNewById({ commit }, newId) {
        const response = await NewsAPI.getNewById(newId)

        commit('SET_SELECTED_NEW', response.data)
    },

    async getNewsByCategoryId({ commit }, categoryId) {
        const response = await NewsAPI.getNewsByCategoryId(categoryId)

        commit('SET_NEWS', response.data)
    },
}

const mutations = {
    SET_MAIN_NEWS(state, payload) {
        state.mainNews = payload
    },

    SET_SUB_NEWS(state, payload) {
        state.subNews = payload
    },

    SET_SELECTED_NEW(state, payload) {
        state.selectedNew = payload
    },

    SET_PAGINATED_NEWS(state, payload) {
        state.paginatedNews = payload
    }
}

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
}
