/* Модуль API с общими запрсами */

// eslint-disable
import API from './api'

const configUrl = '/config/'

export const ConfigAPI = {
    async getConfigHeading() {
        /**
         * Получение заголовка и подзаголовка сайта
         */

        return API.get(`${configUrl}heading/`)
    },

    async getConfigIframeLink() {
        /**
         * Получение сслыки для iframe
         */

        return API.get(`${configUrl}madd-iframe/`)
    },
}
