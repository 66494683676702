<template>
    <button
        :type="type"
        class="btn button"
        :class="getClassList"
        @click="onClick"
    >
        <slot />
        <slot name="icon" />
    </button>
</template>

<script>
import {computed, toRefs} from "vue";

export default {
    name: "Button",

    props: {
        type: {
            type: String,
            default: 'button'
        },

        variant: {
            type: String,
            default: 'primary'
        }
    },

    emits: ['click', 'submit'],

    setup(props, { emit }) {
        const { type } = toRefs(props)

        /* Computed */
        const getClassList = computed(() => {
            const { variant } = toRefs(props)

            const result = [
                `button--${variant.value}`
            ]

            return result
        })

        /* Methods */
        const onClick = () => {
            type.value === 'submit' ? emit('submit') : emit('click')
        }

        return {
            getClassList,
            onClick,
        };
    }
}
</script>
