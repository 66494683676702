/* Модуль API с запросами к виджетам */

// eslint-disable
import API from './api'

const widgetUrl = '/widgets/'

export const WidgetAPI = {
    async getMainPageWidgets() {
        /**
         * Получение виджетов на главной странице
         */

        return API.get(`${widgetUrl}main-page/`)
    },

    async getPartnershipAgreementWidgets() {
        /**
         * Получение виджетов для соглашения о партнерстве
         */

        return API.get(`${widgetUrl}partnership-agreement/`)
    },

    async getAccompanimentConsultingFinancingWidgets() {
        /**
         * Получение виджетов для табов в инновационно-коммуникационном партнерстве
         */

        return API.get(`${widgetUrl}accompaniment-consulting-financing/`)
    },

    async getClusterDevelopmentOptionsWidgets() {
        /**
         * Получение категорий разработок для виджета "Разработки Университета инфо"
         */

        return API.get(`${widgetUrl}cluster-development-options/`)
    },

    async getEducationalPartnershipStages() {
        /**
         * Получение этапов на странице Образовательного партнерства
         */

        return API.get(`${widgetUrl}educational-partnership-stages/`)
    },

    async getEducationalPartnershipStats() {
        /**
         * Получение статистики на странице Образовательного партнерства
         */

        return API.get(`${widgetUrl}educational-partnership-stats/`)
    },

    async getExpertiseDirectionsWidgets() {
        /**
         * Получение текста для нумерованых виджетов на странице Научно-техничесая экспертиза
         */

        return API.get(`${widgetUrl}expertise-directions/`)
    },

    async getStrategicPartnershipDirectionsWidgets() {
        /**
         * Получение виджетов на странице стратегического партнерства
         */

        return API.get(`${widgetUrl}strategic-partnership-directions/`)
    },

    async getTechnoparkStats() {
        /**
         * Получение статистики для технопарка
         */

        return API.get(`${widgetUrl}technopark-stats/`)
    },

    async getColorfulServices() {
        /**
         * Получение цветных карточек услуг
         */

        return API.get('pages/service-colorful-cards/')
    },

    async getBasicServices() {
        /**
         * Получение бесцветных карточек услуг
         */

        return API.get('pages/service-basic-cards/')
    },

}
