<template>
    <header class="header position-sticky w-100 top-0">
        <div class="header__top d-flex align-items-center justify-content-md-between">
            <div
                class="d-flex align-items-center"
                style="cursor: pointer;"
                @click="goToPage('Main')"
            >
                <img v-if="isMenuVisible" src="../../assets/components/header/logo-light.svg" alt="">
                <img v-else src="../../assets/components/header/logo-dark.svg" alt="">

                <div
                    class="header__title ms-2"
                    :style="getHeaderTitleStyle"
                >
                    {{ siteHeading }}
                </div>
            </div>

            <button
                type="button"
                class="btn rounded-circle ms-auto ms-md-0"
                @click="toggleMenu"
            >
                <img
                    v-if="isMenuVisible"
                    src="../../assets/components/header/menu-close.svg"
                    alt=""
                >

                <img
                    v-else
                    src="../../assets/components/header/menu.svg"
                    alt=""
                >
            </button>

            <div class="d-flex position-relative">
                <img
                    v-if="!isMenuVisible"
                    class="header__itmo-logo position-absolute"
                    src="../../assets/components/header/itmo-ttl.svg" alt=""
                    style="left: -40%; top: 30%"
                    width="152"
                    height="20"
                >

                <img
                    v-else
                    class="header__itmo-logo position-absolute"
                    src="../../assets/components/header/itmo-ttl-white.svg" alt=""
                    style="left: -40%; top: 30%"
                    width="152"
                    height="20"
                >

                <Button
                    class="ms-4 d-none d-md-block"
                    :variant="isMenuVisible ? 'secondary' : 'primary'"
                    data-bs-toggle="modal"
                    data-bs-target="#partnerModal"
                    @click="openModal"
                    @mouseenter="isHover = true"
                    @mouseleave="isHover = false"
                >
                    <template #default>
                        Стать партнером
                    </template>
                </Button>

                <Button
                    class="ms-4 d-none d-md-block"
                    :variant="isMenuVisible ? 'secondary' : 'primary'"
                    @mouseenter="isHover = true"
                    @mouseleave="isHover = false"
                >
                    <template #default>
                        <a href="https://tt.itmo.ru/pa" class="text-white text-decoration-none">Войти в ЛК ЦТТ</a>
                    </template>
                </Button>
            </div>
        </div>

        <nav v-show="isMenuVisible" class="header__nav w-100" style="overflow: auto;max-height: 92vh;">
            <div class="header__catalog d-flex align-items-center flex-wrap pt-4">
                <div class="header__services-text me-4">
                    <router-link :to="{ name: 'ProjectExpertise' }">
                        Услуги:
                    </router-link>
                </div>

                <Badge
                    v-for="(service, colorfulIndex) in services.colorful"
                    :key="colorfulIndex"
                    variant="custom-services"
                    class="me-2 mt-1"
                    style="--developments-btn-custom-bg-color: white; --developments-btn-custom-marker-color: white; color: black !important;"
                >
                    <template #default>
                        <a :href="service.link" class="text-decoration-none text-black"><span>{{ service.title }}</span></a>
                    </template>
                </Badge>
            </div>
            
            <div class="header__catalog d-flex align-items-center flex-wrap pt-2">
                <div class="header__catalog-text me-4">
                    <router-link :to="{ name: 'Developments' }">
                        Каталог разработок:
                    </router-link>
                </div>

                <Badge
                    v-for="(productCategory, categoryIndex) in productCategories"
                    :key="categoryIndex"
                    variant="custom"
                    class="me-2 mt-1"
                    :style="`--developments-btn-custom-bg-color: ${productCategory.colour}; --developments-btn-custom-marker-color: ${productCategory.marker_colour}`"
                    @click="goToPage('Developments', productCategory.id)"
                >
                    <template #default>
                        <span>{{ productCategory.title }}</span>
                    </template>
                </Badge>
            </div>

            <div class="header__buttons mt-1">
                <Button
                    class="d-block d-md-none"
                    variant="primary"
                    data-bs-toggle="modal"
                    data-bs-target="#partnerModal"
                    @click="openModal"
                >
                    <template #default>
                        Стать партнером
                    </template>
                </Button>
    
                <Button
                    class="ms-3 d-block d-md-none "
                    variant="primary"
                    @mouseenter="isHover = true"
                    @mouseleave="isHover = false"
                >
                    <template #default>
                        <a href="https://tt.itmo.ru/pa" class="text-white text-decoration-none">Войти в ЛК ЦТТ</a>
                    </template>
                </Button>
            </div>

            <div class="header__categories d-flex flex-column flex-sm-row flex-wrap mt-5 pb-4">
                <div class="nav-category">
                    <div class="nav-category__title mb-3">
                        <router-link :to="{ name: 'Partnership' }">Партнерство</router-link>
                    </div>

                    <div class="nav-category__item my-3">
                        <router-link :to="{ name: 'InnovativePartnership' }">
                            Инновационно-коммуникационное партнерство
                        </router-link>
                    </div>
                    <div class="nav-category__item my-3">
                        <router-link :to="{ name: 'ScientificPartnership' }">
                            Научное партнерство
                        </router-link>
                    </div>
                    <div class="nav-category__item my-3">
                        <router-link :to="{ name: 'StrategicPartnership' }">
                            Стратегическое партнерство
                        </router-link>
                    </div>
                    <div class="nav-category__item my-3">
                        <router-link :to="{ name: 'EducationalPartnership' }">
                            Образовательное партнерство
                        </router-link>
                    </div>
                </div>

                <div class="nav-category ms-sm-3 mt-4 mt-sm-0">
                    <div class="nav-category__title mb-3">
                        <router-link :to="{ name: 'TechnologyTransfer' }">
                            Трансфер технологий
                        </router-link>
                    </div>

                    <div class="nav-category__item my-3">
                        <router-link :to="{ name: 'TransferTechnologyCenter' }">
                            Центр трансфера технологий <br> Университета ИТМО
                        </router-link>
                    </div>
                    <div class="nav-category__item my-3">
                        <router-link :to="{ name: 'Technopark' }">
                            Акселератор и Технопарк
                        </router-link>
                    </div>
                    <div class="nav-category__item my-3">
                        <router-link :to="{ name: 'IPExchange' }">
                            Проект «IP Биржа»
                        </router-link>
                    </div>
                    <div class="nav-category__item my-3">
                        <router-link :to="{ name: 'Qualification' }">
                            Повышение квалификации
                        </router-link>
                    </div>
                    <div class="nav-category__item my-3">
                        <router-link :to="{ name: 'Highpark' }">
                            ИТМО Хайпарк
                        </router-link>
                    </div>
                    <div class="nav-category__item my-3">
                        <router-link :to="{ name: 'Fund' }">
                            Эндаумент-фонд Университета ИТМО
                        </router-link>
                    </div>
                    <div class="nav-category__item my-3">
                        <router-link :to="{ name: 'LawDigest' }">
                            Правовые дайджесты
                        </router-link>
                    </div>
                    <div class="nav-category__item my-3">
                        <router-link :to="{ name: 'DigitalTools' }">
                            Цифровые инструменты ЦТТ
                        </router-link>
                    </div>
                </div>

                <div class="nav-category ms-md-3 mt-4 mt-md-0">
                    <div class="nav-category__title mb-3">
                        <router-link :to="{ name: 'StrategicProjects' }">
                            Стратегические проекты
                        </router-link>
                    </div>

                    <div class="nav-category__item my-3">
                        <router-link :to="{ name: 'ResearchCenter' }">
                            Исследовательский центр <br> «Сильный искусственный интеллект в промышленности»
                        </router-link>
                    </div>
                    <div class="nav-category__item my-3">
                        <router-link :to="{ name: 'NCCR' }">
                            Национальный центр когнитивных разработок
                        </router-link>
                    </div>
                    <div class="nav-category__item my-3">
                        <router-link :to="{ name: 'NCCI' }">
                            Национальный центр квантового интернета
                        </router-link>
                    </div>
                    <div class="nav-category__item my-3">
                        <router-link :to="{ name: 'EngineeringSchool' }">
                            Передовая инженерная школа
                        </router-link>
                    </div>
                    <div class="nav-category__item my-3">
                        <router-link :to="{ name: 'InnovativeCenterGpn' }">
                            ИЦ ГПН-ИТМО
                        </router-link>
                    </div>
                </div>

                <div class="mt-2 mt-sm-auto ms-sm-auto">
                    <div class="header__contact">
                        <router-link :to="{ name: 'Contacts' }">
                            Контакты

                           <img class="ms-1" src="../../assets/arrow-aside-white.svg" alt="">
                        </router-link>
                    </div>
                    <div class="header__contact mt-3">+7 (812) 480-99-99</div>
                    <div class="header__contact">
                        <a href="mailto:tt@itmo.ru">tt@itmo.ru</a>
                    </div>
                </div>
            </div>

            <div class="header__bottom d-flex flex-column flex-md-row align-items-md-center py-3">
                <div class="header__bottom-text">Проект центра трансфера технологий Университета ИТМО</div>
                <div class="mt-2 mb-1 ms-auto">
                    <img
                        src="../../assets/uni-and-science.png" alt=""
                        height="30"
                    >
                </div>
                <div class="mt-2 mb-1">
                    <img
                        @click="redirectToItmo"
                        src="../../assets/components/header/itmo-logo.png" alt=""
                        height="30"
                        style="cursor: pointer"
                    >
                </div>
            </div>
        </nav>
    </header>

    <BecomePartnerModal @hide="showSuccessToast" />

    <div class="position-fixed bottom-0 end-0 p-3" style="z-index: 11">
        <div id="successSentToast" class="toast hide bg-success text-white" style="--bs-success-rgb: 82, 169, 156" role="alert" aria-live="assertive" aria-atomic="true">
            <div class="toast-header">
                <strong class="me-auto">Успешно</strong>
                <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
            </div>
            <div class="toast-body">
                Сообщение успешно отправлено, скоро с вами свяжутся.
            </div>
        </div>
    </div>
</template>

<script>
import { onMounted, computed, ref } from 'vue';
import Button from "@/components/common/Button/Button";
import Badge from "@/components/common/Badge/Badge";
import BecomePartnerModal from "@/components/BecomePartnerModal/PartnerModal";
import routerMixin from "@/mixins/routerMixin";
import { useStore } from "vuex";
import { Toast } from "bootstrap";

export default {
    name: "Header",

    components: {
        Button,
        Badge,
        BecomePartnerModal
    },

    setup() {
        const { isMenuVisible, toggleMenu, goToPage, redirectToItmo } = routerMixin();

        const isHover = ref(false)

        const store = useStore()

        const services = computed(() => store.getters['widgets/services'])

        onMounted(async () => {
            await store.dispatch('productCategories/getProductCategories')
        })

        /* Computed */
        const productCategories = computed(() => store.getters['productCategories/productCategories'])

        const getHeaderTitleStyle = computed(() => {
            const style = {
                color: 'black'
            }

            document.querySelector('html').style.overflow = 'auto'

            if (isMenuVisible.value) {
                style.color = 'white'

                document.querySelector('html').style.overflow = 'hidden'
            }

            return style
        });

        const siteHeading = computed(() => store.getters['config/siteHeading'])

        const isPartnersApplicationSent = computed(() => store.getters['partners/partnersApplicationSent'])

        const showSuccessToast = () => {
            if (isPartnersApplicationSent.value) {
                const toastEl = document.querySelector('#successSentToast')
                const toast = new Toast(toastEl)
                toast.show()

                store.dispatch('partners/setPartnersApplicationSent', false)

            }
        }

        const openModal = () => {
            if (isMenuVisible.value) {
                toggleMenu()
            }
        }

        return {
            isMenuVisible,
            siteHeading,
            productCategories,
            getHeaderTitleStyle,
            isHover,
            toggleMenu,
            goToPage,
            openModal,
            showSuccessToast,
            redirectToItmo,
            services,
        };
    }
}
</script>
