/* Модуль API с запросами табам рядом с видео на главной странице */

// eslint-disable
import API from './api'

const headerUrl = '/header/nav-tabs/'

export const HeaderAPI = {
    async getHeaderTabs() {
        /**
         * Получение пунктов для табов рядом с видео на главной странице
         */

        return API.get(`${headerUrl}`)
    },

    async getHeaderTabById(headerTabId) {
        /**
         * Получение пунктов для таба по его id рядом с видео на главной странице
         */

        return API.get(`${headerUrl}${headerTabId}/`)
    },
}
