// eslint-disable
import { PagesAPI } from '@/api/pages';

const state = {
    acceleratorTechnoparkPage: null, // Акселератор и Технопарк
    cttPage: null, // Центр трансфера технологий Университета ИТМО
    educationalPartnershipPage: null, // Образовательное партнерство
    fundPage: null, // Эндаумент-фонд Университета ИТМО
    highparkPage: null, // ИТМО Хайпарк
    reserchCenterPage: null, // "ИССЛЕДОВАТЕЛЬСКИЙ ЦЕНТР «СИЛЬНЫЙ ИСКУССТВЕННЫЙ ИНТЕЛЛЕКТ В ПРОМЫШЛЕННОСТИ»
    innovativePartnershipPage: null, // Инновационно-коммуникационное партнерство
    nccrPage: null, // NCCR
    ncciPage: null, // NCCI
    partnershipPage: null, // Партнерство (основная страница).
    projectsExpertisePage: null, // Экспертиза проектов.
    scientificPartnershipPage: null, // Научное парнерство
    strategicPartnershipPage: null, // Стратегическое партнерство
    strategicProjectsPage: null, // Стратегические проекты (основная страница)
    technologyTransferPage: null, // Трансфер технологий (основная страница)
}

const getters = {
    acceleratorTechnoparkPage: (s) => s.acceleratorTechnoparkPage,
    cttPage: (s) => s.cttPage,
    educationalPartnershipPage: (s) => s.educationalPartnershipPage,
    fundPage: (s) => s.fundPage,
    highparkPage: (s) => s.highparkPage,
    reserchCenterPage: (s) => s.reserchCenterPage,
    innovativePartnershipPage: (s) => s.innovativePartnershipPage,
    nccrPage: (s) => s.nccrPage,
    ncciPage: (s) => s.ncciPage,
    partnershipPage: (s) => s.partnershipPage,
    projectsExpertisePage: (s) => s.projectsExpertisePage,
    scientificPartnershipPage: (s) => s.scientificPartnershipPage,
    strategicPartnershipPage: (s) => s.strategicPartnershipPage,
    strategicProjectsPage: (s) => s.strategicProjectsPage,
    technologyTransferPage: (s) => s.technologyTransferPage,
}

const actions = {
    async getAcceleratorTechnopark({ commit }) {
        const response = await PagesAPI.getAcceleratorTechnoparkPage()

        commit('SET_ACCELERATOR_TECHNOPARK_PAGE', response.data)
    },

    async getCttPage({ commit }) {
        const response = await PagesAPI.getCttPage()

        commit('SET_CTT_PAGE', response.data)
    },

    async getEducationalPartnershipPage({ commit }) {
        const response = await PagesAPI.getEducationalPartnershipPage()

        commit('SET_EDUCATIONAL_PARTNERSHIP_PAGE', response.data)
    },

    async getFundPage({ commit }) {
        const response = await PagesAPI.getFundPage()

        commit('SET_FUND_PAGE', response.data)
    },

    async getHighparkPage({ commit }) {
        const response = await PagesAPI.getHighparkPage()

        commit('SET_HIGHPARK_PAGE', response.data)
    },

    async getResearchCenterPage({ commit }) {
        const response = await PagesAPI.getResearchCenterPage()

        commit('SET_RESEARCH_CENTER_PAGE', response.data)
    },

    async getInnovativePartnershipPage({ commit }) {
        const response = await PagesAPI.getInnovativePartnershipPage()

        commit('SET_INNOVATIVE_PARTNERSHIP_PAGE', response.data)
    },

    async getNccrPage({ commit }) {
        const response = await PagesAPI.getNccrPage()

        commit('SET_NCCR_PAGE', response.data)
    },

    async getNcciPage({ commit }) {
        const response = await PagesAPI.getNcciPage()

        commit('SET_NCCI_PAGE', response.data)
    },

    async getPartnershipPage({ commit }) {
        const response = await PagesAPI.getPartnershipPage()

        commit('SET_PARTNERSHIP_PAGE', response.data)
    },

    async getProjectsExpertisePage({ commit }) {
        const response = await PagesAPI.getProjectsExpertisePage()

        commit('SET_PROJECT_EXPERTISE_PAGE', response.data)
    },

    async getScientificPartnershipPage({ commit }) {
        const response = await PagesAPI.getScientificPartnershipPage()

        commit('SET_SCIENTIFIC_PARTNERSHIP_PAGE', response.data)
    },

    async getStrategicPartnershipPage({ commit }) {
        const response = await PagesAPI.getStrategicPartnershipPage()

        commit('SET_STRATEGIC_PARTNERSHIP_PAGE', response.data)
    },

    async getStrategicProjectsPage({ commit }) {
        const response = await PagesAPI.getStrategicProjectsPage()

        commit('SET_STRATEGIC_PROJECTS_PAGE', response.data)
    },

    async getTechnologyTransferPage({ commit }) {
        const response = await PagesAPI.getTechnologyTransferPage()

        commit('SET_TECHNOLOGY_TRANSFER_PAGE', response.data)
    },
}

const mutations = {
    SET_ACCELERATOR_TECHNOPARK_PAGE(state, payload) {
        state.acceleratorTechnoparkPage = payload
    },

    SET_CTT_PAGE(state, payload) {
        state.cttPage = payload
    },

    SET_EDUCATIONAL_PARTNERSHIP_PAGE(state, payload) {
        state.educationalPartnershipPage = payload
    },

    SET_FUND_PAGE(state, payload) {
        state.fundPage = payload
    },

    SET_HIGHPARK_PAGE(state, payload) {
        state.highparkPage = payload
    },

    SET_RESEARCH_CENTER_PAGE(state, payload) {
        state.reserchCenterPage = payload
    },

    SET_INNOVATIVE_PARTNERSHIP_PAGE(state, payload) {
        state.innovativePartnershipPage = payload
    },

    SET_NCCR_PAGE(state, payload) {
        state.nccrPage = payload
    },

    SET_NCCI_PAGE(state, payload) {
        state.ncciPage = payload
    },

    SET_PARTNERSHIP_PAGE(state, payload) {
        state.partnershipPage = payload
    },

    SET_PROJECT_EXPERTISE_PAGE(state, payload) {
        state.projectsExpertisePage = payload
    },

    SET_SCIENTIFIC_PARTNERSHIP_PAGE(state, payload) {
        state.scientificPartnershipPage = payload
    },

    SET_STRATEGIC_PARTNERSHIP_PAGE(state, payload) {
        state.strategicPartnershipPage = payload
    },

    SET_STRATEGIC_PROJECTS_PAGE(state, payload) {
        state.strategicProjectsPage = payload
    },

    SET_TECHNOLOGY_TRANSFER_PAGE(state, payload) {
        state.technologyTransferPage = payload
    },
}

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
}
