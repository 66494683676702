<template>
    <footer class="footer">
        <div class="d-flex flex-column flex-sm-row flex-wrap pt-5 footer-categories">
            <div class="d-flex flex-column nav-category">
                <div
                    class="d-flex"
                    style="cursor: pointer;"
                    @click="goToPage('Main')"
                >
                    <img src="../../assets/components/header/logo-light.svg" alt="">

                    <div class="footer__title ms-2">
                        {{ siteHeading }}
                    </div>
                </div>

                <div class="d-flex flex-column mt-4">
                    <div class="footer_project-description">
                        Проект центра трансфера технологий <br> Университета ИТМО
                    </div>
                    <div class="footer__contact mt-3">
                        <router-link class="d-flex align-items-center" :to="{ name: 'Contacts' }">
                            <span class="text-white h6 my-auto">Контакты</span>

                           <img class="ms-2" src="../../assets/arrow-aside-white.svg" alt="" width="12">
                        </router-link>
                    </div>
                    <div class="footer__contact mt-2">+7 (812) 480-99-99</div>
                    <div class="footer__contact mt-2">
                        <a href="mailto:tt@itmo.ru">tt@itmo.ru</a>
                    </div>
                </div>
            </div>

            <div class="d-flex flex-column nav-category mt-4 mt-sm-0">
                <div class="nav-category__title mb-2">
                    <router-link :to="{ name: 'Partnership' }" @click="goToPageTop">
                        Партнерство
                    </router-link>
                </div>
                <div class="nav-category__item">
                    <router-link :to="{ name: 'InnovativePartnership' }" @click="goToPageTop">
                        Инновационно-коммуникационное партнерство
                    </router-link>
                </div>
                <div class="nav-category__item mt-2">
                    <router-link :to="{ name: 'ScientificPartnership' }" @click="goToPageTop">
                        Научное партнерство
                    </router-link>
                </div>
                <div class="nav-category__item mt-2">
                    <router-link :to="{ name: 'StrategicPartnership' }" @click="goToPageTop">
                        Стратегическое партнерство
                    </router-link>
                </div>
                <div class="nav-category__item mt-2">
                    <router-link :to="{ name: 'EducationalPartnership' }" @click="goToPageTop">
                        Образовательное партнерство
                    </router-link>
                </div>
            </div>

            <div class="d-flex flex-column nav-category ms-md-4 mt-4 mt-md-0">
                <div class="nav-category__title mb-2">
                    <router-link :to="{ name: 'TechnologyTransfer' }" @click="goToPageTop">
                        Трансфер технологий
                    </router-link>
                </div>

                <div class="nav-category__item">
                    <router-link :to="{ name: 'TransferTechnologyCenter' }">
                        Центр трансфера технологий <br> Университета ИТМО
                    </router-link>
                </div>
                <div class="nav-category__item mt-2">
                    <router-link :to="{ name: 'Technopark' }">
                        Акселератор и Технопарк
                    </router-link>
                </div>
                <div class="nav-category__item mt-2">
                    <router-link :to="{ name: 'IPExchange' }">
                        Проект «IP Биржа»
                    </router-link>
                </div>
                <div class="nav-category__item mt-2">
                    <router-link :to="{ name: 'Qualification' }">
                        Повышение квалификации
                    </router-link>
                </div>
                <div class="nav-category__item mt-2">
                    <router-link :to="{ name: 'Highpark' }">
                        ИТМО Хайпарк
                    </router-link>
                </div>
                <div class="nav-category__item mt-2">
                    <router-link :to="{ name: 'Fund' }">
                        Эндаумент-фонд Университета ИТМО
                    </router-link>
                </div>
                <div class="nav-category__item mt-2">
                    <router-link :to="{ name: 'LawDigest' }">
                        Правовые дайджесты
                    </router-link>
                </div>
                <div class="nav-category__item mt-2">
                    <router-link :to="{ name: 'DigitalTools' }">
                        Цифровые инструменты ЦТТ
                    </router-link>
                </div>
            </div>

            <div class="d-flex flex-column nav-category ms-sm-4 mt-4 mt-md-0">
                <div class="nav-category__title mb-2">
                    <router-link :to="{ name: 'StrategicProjects' }" @click="goToPageTop">
                        Стратегические проекты
                    </router-link>
                </div>

                <div class="nav-category__item">
                    <router-link :to="{ name: 'ResearchCenter' }">
                        Исследовательский центр <br> «Сильный искусственный интеллект в промышленности»
                    </router-link>
                </div>
                <div class="nav-category__item mt-2">
                    <router-link :to="{ name: 'NCCR' }">
                        Национальный центр когнитивных разработок
                    </router-link>
                </div>
                <div class="nav-category__item mt-2">
                    <router-link :to="{ name: 'NCCI' }">
                        Национальный центр квантового интернета
                    </router-link>
                </div>
                <div class="nav-category__item mt-2">
                    <router-link :to="{ name: 'EngineeringSchool' }">
                        Передовая инженерная школа
                    </router-link>
                </div>
                <div class="nav-category__item mt-2">
                    <router-link :to="{ name: 'InnovativeCenterGpn' }">
                        ИЦ ГПН-ИТМО
                    </router-link>
                </div>
            </div>

            <div
                class="d-flex flex-row align-self-md-end align-self-lg-start flex-lg-column mt-4 mt-lg-0 ms-lg-auto flex-nowrap ms-md-4">
                <div class="footer__icon">
                    <img @click="goToLink('vk')" src="../../assets/components/footer/vk.svg" alt="">
                </div>
                <div class="ms-3 ms-lg-0  mt-lg-3 footer__icon">
                    <img @click="goToLink('tg')" src="../../assets/components/footer/tg.svg" alt="">
                </div>
                <div class="ms-3 ms-lg-0  mt-lg-3 footer__icon">
                    <img @click="goToLink('youtube')" src="../../assets/components/footer/youtube.svg" alt="">
                </div>
            </div>
        </div>

        <div class="footer__catalog d-flex align-items-center flex-wrap mt-4 py-4">
            <div class="footer__services-text me-4">
                <router-link :to="{ name: 'ProjectExpertise' }">
                    Услуги:
                </router-link>
            </div>

            <Badge
                v-for="(service, colorfulIndex) in services.colorful"
                :key="colorfulIndex"
                variant="custom-services"
                class="me-2 mt-1"
                style="--developments-btn-custom-bg-color: white; --developments-btn-custom-marker-color: white; color: black !important;"
            >
                <template #default>
                    <a :href="service.link" class="text-decoration-none text-black"><span>{{ service.title }}</span></a>
                </template>
            </Badge>
        </div>

        <div class="footer__catalog d-flex align-items-center flex-wrap pb-4">
            <div class="footer__catalog-text me-4">
                <router-link :to="{ name: 'Developments' }">
                    Каталог разработок:
                </router-link>
            </div>

            <Badge
                v-for="(productCategory, categoryIndex) in productCategories"
                :key="categoryIndex"
                variant="custom"
                class="me-2 mt-1"
                :style="`--developments-btn-custom-bg-color: ${productCategory.colour}; --developments-btn-custom-marker-color: ${productCategory.marker_colour}`"
                @click="goToPage('Developments', productCategory.id)"
            >
                <template #default>
                    <span>{{ productCategory.title }}</span>
                </template>
            </Badge>
        </div>

        <div class="d-flex flex-column flex-md-row footer__bottom align-items-md-center">
            <div>
                <img
                    src="../../assets/components/header/itmo-logo.png" alt=""
                    class="footer__itmo-logo"
                    @click="redirectToItmo"
                    style="cursor: pointer"
                >
            </div>

            <div>
                <img
                    src="../../assets/uni-and-science.png" alt=""
                    class="footer__itmo-logo"
                >
            </div>

            <div class="mt-3 mt-md-0 ms-md-auto footer__rights">© {{ getCurrentYear }} B2B ITMO. All rights reserved.</div>
        </div>
    </footer>
</template>

<script>
import { computed, onMounted } from "vue";
import Badge from "@/components/common/Badge/Badge";
import routerMixin from "@/mixins/routerMixin";
import { useStore } from "vuex";
import { SOCIAL_MEDIA_LINKS } from "@/utils/constants";

export default {
    name: "Footer",

    components: {
        Badge,
    },

    setup() {
        const store = useStore()

        const services = computed(() => store.getters['widgets/services'])

        const { goToPage, goToPageTop, redirectToItmo } = routerMixin();

        const goToLink = socialMedia => {
            let link = ''
            for (const key in SOCIAL_MEDIA_LINKS) {
                if (key === socialMedia) link = SOCIAL_MEDIA_LINKS[key];
            }
            const url = document.createElement('a');
            url.href = link;
            url.target = '_blank';
            const event = new MouseEvent('click', {
                'view': window,
                'bubbles': false,
                'cancelable': true
            });
            url.dispatchEvent(event);
        }

        onMounted(async () => {
            await store.dispatch('widgets/getServices')
            await store.dispatch('productCategories/getProductCategories')
        })

        /* Computed */
        const productCategories = computed(() => store.getters['productCategories/productCategories'])

        const getCurrentYear = computed(() => {
            return new Date().getFullYear()
        })

        const siteHeading = computed(() => store.getters['config/siteHeading'])

        return {
            getCurrentYear,
            siteHeading,
            productCategories,
            goToPage,
            goToLink,
            goToPageTop,
            redirectToItmo,
            services,
        }
    }
}
</script>
