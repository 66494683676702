// eslint-disable
import { ProductCategoriesAPI } from '@/api/productCategories';

const state = {
    productCategories: [], // Список категорий разработок
    selectedProductCategory: null, // Выбранная категория разработок
}

const getters = {
    productCategories: (s) => s.productCategories,
    selectedProductCategory: (s) => s.selectedProductCategory,
}

const actions = {
    async getProductCategories({ commit }) {
        const response = await ProductCategoriesAPI.getProductCategories()

        commit('SET_PRODUCT_CATEGORIES', response.data)
    },

    async getProductCategoryById({ commit }, categoryId) {
        const response = await ProductCategoriesAPI.getProductCategoryById(categoryId)

        commit('SET_SELECTED_PRODUCT_CATEGORY', response.data)
    },
}

const mutations = {
    SET_PRODUCT_CATEGORIES(state, payload) {
        state.productCategories = payload
    },

    SET_SELECTED_PRODUCT_CATEGORY(state, payload) {
        state.selectedProductCategory = payload
    },
}

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
}
