// eslint-disable
import { ConfigAPI } from '@/api/config';

const state = {
    siteHeading: '', // Название сайта
    siteSubheading: '', // Подзаголвок сайта

    iframeLink: '' // Ссылка для iframe на главной
}

const getters = {
    siteHeading: (s) => s.siteHeading,
    siteSubheading: (s) => s.siteSubheading,

    iframeLink: (s) => s.iframeLink,
}

const actions = {
    async getSiteHeading({ commit }) {
        const response = await ConfigAPI.getConfigHeading()

        const { site_heading, site_subheading } = response.data
        commit('SET_SITE_HEADING', site_heading)
        commit('SET_SITE_SUBHEADING', site_subheading)
    },

    async getIframeLink({ commit }) {
        const response = await ConfigAPI.getConfigIframeLink()

        const { madd_iframe_url } = response.data
        commit('SET_IFRAME_LINK', madd_iframe_url)
    },
}

const mutations = {
    SET_SITE_HEADING(state, payload) {
        state.siteHeading = payload
    },

    SET_SITE_SUBHEADING(state, payload) {
        state.siteSubheading = payload
    },

    SET_IFRAME_LINK(state, payload) {
        state.iframeLink = payload
    },
}

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
}
