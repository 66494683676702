<template>
  <div class="d-flex justify-content-center">
    <div v-if="showCookieBanner" class="cookie-banner d-flex position-fixed col-12 col-md-6">
      <div>
        <p class="cookie-banner__text">Для улучшения работы сайта мы используем файлы cookie.</p>
        <p class="cookie-banner__text">Продолжая пользоваться сайтом, вы соглашаетесь на их использование.</p>
      </div>
      <button @click="toggleCookieBanner" class="cookie-banner__btn align-self-center">ПРИНЯТЬ</button>
    </div>
  </div>
</template>

<script>
import './cookie-banner.css';
import { computed } from 'vue';
import { useStore } from "vuex";

export default {
  name: "CookieBanner",

  components: {},

  setup() {
    const store = useStore()

    const toggleCookieBanner = () => {
      store.dispatch('base/toggleCookieBanner')
    }

    const showCookieBanner = computed(() => store.getters['base/showCookieBanner']);

    return {
      showCookieBanner,
      toggleCookieBanner
    }
  }

}
</script>
