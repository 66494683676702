// eslint-disable
import { HeaderAPI } from '@/api/header';

const state = {
    headerTabs: [], // Пункты для табов рядом с видео на главной странице
    selectedHeaderTab: null, // Выбранный таб
}

const getters = {
    headerTabs: (s) => s.headerTabs,
    selectedHeaderTab: (s) => s.selectedHeaderTab,
}

const actions = {
    async getHeaderTabs({ commit }) {
        const response = await HeaderAPI.getHeaderTabs()

        commit('SET_HEADER_TABS', response.data)
    },

    async getHeaderTabById({ commit }, headerTabId) {
        const response = await HeaderAPI.getHeaderTabById(headerTabId)

        commit('SET_SELECTED_HEADER_TAB', response.data)
    },
}

const mutations = {
    SET_HEADER_TABS(state, payload) {
        state.headerTabs = payload
    },

    SET_SELECTED_HEADER_TAB(state, payload) {
        state.selectedHeaderTab = payload
    },
}

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
}
