/* Модуль API с запросами к категориям новостей */

// eslint-disable
import API from './api'

const newsCategoriesUrl = '/news-categories/'

export const NewsCategoriesAPI = {
    async getNewsCategories() {
        /**
         * Получение списка категорий новостей
         */

        return API.get(`${newsCategoriesUrl}`)
    },

    async getNewsCategoryById(categoryId) {
        /**
         * Получение категории новости по ее id
         */

        return API.get(`${newsCategoriesUrl}${categoryId}`)
    },
}
