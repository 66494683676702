<template>
    <div class="modal fade" id="partnerModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header border-bottom-0">
                    <h4 class="parnter-modal__title modal-title" id="exampleModalLabel">
                        Стать партнером
                    </h4>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>

                <div class="modal-body">
                    <form class="parnter-form" @submit.prevent="onSubmit">
                        <div class="mb-3">
                            <label for="name" class="parnter-form__label form-label">ФИО*</label>
                            <input
                                id="name"
                                v-model="form.name"
                                type="text"
                                class="parnter-form__input form-control"
                                required
                            >
                        </div>

                        <div class="mb-3">
                            <label for="org_name" class="parnter-form__label form-label">Организация</label>
                            <input
                                id="org_name"
                                type="text"
                                v-model="form.org_name"
                                class="parnter-form__input form-control"
                                required
                            >
                        </div>

                        <div class="mb-3">
                            <label for="phone" class="parnter-form__label form-label">Телефон*</label>
                            <input
                                id="phone"
                                v-model="form.phone"
                                type="tel"
                                class="parnter-form__input form-control"
                                placeholder="89998887766"
                                required
                            >
                        </div>

                        <div class="mb-3">
                            <label for="email" class="parnter-form__label form-label">Email</label>
                            <input
                                id="email"
                                v-model="form.email"
                                type="email"
                                class="parnter-form__input form-control"
                                aria-describedby="emailHelp"
                                required
                            >
                        </div>

                        <div class="mb-3">
                            <label for="about_project" class="parnter-form__label form-label">О проекте</label>
                            <textarea
                                v-model="form.about_project"
                                class="form-control parnter-form__textarea"
                                rows="3"
                                id="about_project"
                                required
                            />
                        </div>

                        <div class="modal-footer border-top-0">
                            <Button variant="outline-dark" data-bs-dismiss="modal">
                                <template #default>
                                    Отмена
                                </template>
                            </Button>

                            <Button
                                class="ms-3"
                                type="submit"
                            >
                                <template #default>
                                    Отправить
                                </template>

                                <template #icon>
                                    <img src="../../assets/components/header/arrow-right.svg" alt="" class="button-icon">
                                </template>
                            </Button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ref } from "vue";
import { useStore } from "vuex";

import Button from "@/components/common/Button/Button";

export default {
    name: "BecomePartnerModal",

    components: {
        Button
    },

    emits: 'hide',

    setup(_, { emit }) {
        const store = useStore()

        let form = ref({
            name: '',
            org_name: '',
            phone: '',
            email: '',
            about_project: ''
        })

        const onSubmit = async (event) => {
            const application = JSON.parse(JSON.stringify(form.value))

            if (!application.name) return
            if (!application.org_name) return
            if (!application.phone) return
            if (!application.email) return
            if (!application.about_project) return

            await store.dispatch('partners/sendPartnerApplciation', application)

            const isPartnersApplicationSent = store.getters['partners/partnersApplicationSent']

            if (isPartnersApplicationSent) {
                event.target.reset()
                emit('hide')
                document.querySelector('#partnerModal .btn-close').click()
            }
        }

        return {
            form,
            onSubmit,
        }
    },
}
</script>
