<template>
    <div
        class="d-inline-block button-nav d-flex align-items-center rounded-pill"
        :class="getClassList"
        @click="onClick"
    >
        <slot name="icon"></slot>
        <slot></slot>
    </div>
</template>

<script>
import {computed, toRefs} from "vue";

export default {
    name: "Badge",

    props: {
        variant: {
            type: String,
            required: true
        }
    },

    emits: ['click'],

    setup(props, { emit }) {
        /* Computed */
        const getClassList = computed(() => {
            const { variant } = toRefs(props)

            const result = [
                `button-nav--${variant.value}`
            ]

            return result
        })

        /* Methods */
        const onClick = () => {
            emit('click')
        }

        return {
            getClassList,
            onClick,
        };
    }

}
</script>

<style scoped>

</style>
