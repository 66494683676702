import { createRouter, createWebHistory } from 'vue-router'

const routes = [
    {
        path: '/',
        name: 'Main',
        component: () => import('../views/Main/Main'),
    },
    {
        path: '/news/:id',
        name: 'NewsDetail',
        component: () => import('../views/News/NewsDetail'),
    },
    {
        path: '/news/',
        name: 'News',
        component: () => import('../views/News/News'),
    },
    {
        path: '/developments/:id',
        name: 'Development',
        component: () => import('../views/Development/Development'),
    },
    {
        path: '/developments/',
        name: 'Developments',
        component: () => import('../views/Developments/Developments'),
    },
    /* Партнерство */
    {
        path: '/partnership/',
        name: 'Partnership',
        component: () => import('../views/Parnership/Partnership'),
    },
    {
        path: '/partnership/innovative/',
        name: 'InnovativePartnership',
        component: () => import('../views/InnovativePartnership/InnovativePartnership'),
    },
    {
        path: '/partnership/educational/',
        name: 'EducationalPartnership',
        component: () => import('../views/EducationalPartnership/EducationalPartnership'),
    },
    {
        path: '/partnership/strategic/',
        name: 'StrategicPartnership',
        component: () => import('../views/StrategicPartnership/StrategicPartnership'),
    },
    {
        path: '/partnership/scientific/',
        name: 'ScientificPartnership',
        component: () => import('../views/ScientificPartnership/ScientificPartnership'),
    },
    /* Стратегические проекты */
    {
        path: '/strategic_projects/',
        name: 'StrategicProjects',
        component: () => import('../views/StrategicProjects/StrategicProjects'),
    },
    {
        path: '/strategic_projects/research_center/',
        name: 'ResearchCenter',
        component: () => import('../views/ResearchCenter/ResearchCenter'),
    },
    {
        path: '/strategic_projects/fund/',
        name: 'Fund',
        component: () => import('../views/Fund/Fund'),
    },
    {
        path: '/strategic_projects/ncci/',
        name: 'NCCI',
        component: () => import('../views/NCCI/NCCI'),
    },
    {
        path: '/strategic_projects/nccr/',
        name: 'NCCR',
        component: () => import('../views/NCCR/NCCR'),
    },
    {
        path: '/strategic_projects/highpark/',
        name: 'Highpark',
        component: () => import('../views/Highpark/Highpark'),
    },
    /* Трансфер технологий */
    {
        path: '/technology_transfer/',
        name: 'TechnologyTransfer',
        component: () => import('../views/TechnologyTransfer/TechnologyTransfer'),
    },
    {
        path: '/technology_transfer/technopark/',
        name: 'Technopark',
        component: () => import('../views/Technopark/Technopark'),
    },
    {
        path: '/technology_transfer/project_expertise',
        name: 'ProjectExpertise',
        redirect: { name: 'MarketingFinancial' },
        component: () => import('../views/ProjectExpertise/ProjectExpertise'),
        children: [
            {
                path: 'marketing_financial',
                name: 'MarketingFinancial',
                component: () => import('../views/ProjectExpertise/MarketingFinancial'),
            },
            {
                path: 'scientific_technical',
                name: 'ScientificTechnical',
                component: () => import('../views/ProjectExpertise/ScientificTechnical'),
            },
            {
                path: 'intellectual_property',
                name: 'IntellectualProperty',
                component: () => import('../views/ProjectExpertise/IntellectualProperty'),
            },
            {
                path: 'legal',
                name: 'Legal',
                component: () => import('../views/ProjectExpertise/Legal'),
            }
        ]
    },
    {
        path: '/transfer_technology_center/',
        name: 'TransferTechnologyCenter',
        component: () => import('../views/TTCenter/TTCenter'),
    },
    { path: '/topic/34/48', redirect: '/transfer_technology_center/' },
    {
        path: '/technology_transfer/ipbirzha',
        name: 'IPExchange',
        component: () => import('../views/IPExchange/IPExchange'),
    },

    {
        path: '/technology_transfer/qualification',
        name: 'Qualification',
        component: () => import('../views/Qualification/Qualification'),
    },

    {
        path: '/engineering-school/',
        name: 'EngineeringSchool',
        component: () => import('../views/EngineeringSchool/EngineeringSchool'),
    },

    {
        path: '/innovative-center-gpn/',
        name: 'InnovativeCenterGpn',
        component: () => import('../views/InnovativeCenterGpn/InnovativeCenterGpn'),
    },

    {
        path: '/law-digest/',
        name: 'LawDigest',
        component: () => import('../views/LawDigest/LawDigest'),
    },

    {
        path: '/digital-tools/',
        alias: '/digital_tools/',
        name: 'DigitalTools',
        component: () => import('../views/DigitalTools/DigitalTools'),
    },

    {
        path: '/contacts/',
        name: 'Contacts',
        component: () => import('../views/Contacts/Contacts'),
    },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
    linkActiveClass: "active",
})

export default router
