/* Модуль API с запросами к страницам */

// eslint-disable
import API from './api'

const pagesUrl = '/pages/'

export const PagesAPI = {
    async getAcceleratorTechnoparkPage() {
        /**
         * Получение информации для страницы Акселератор и Технопарк
         */

        return API.get(`${pagesUrl}accelerator-technopark/`)
    },

    async getCttPage() {
        /**
         * Получение информации для страницы Центр трансфера технологий Университета ИТМО
         */

        return API.get(`${pagesUrl}ctt/`)
    },

    async getEducationalPartnershipPage() {
        /**
         * Получение информации для страницы Образовательное партнерство
         */

        return API.get(`${pagesUrl}educational-partnership/`)
    },

    async getFundPage() {
        /**
         * Получение информации для страницы Эндаумент-фонд Университета ИТМО
         */

        return API.get(`${pagesUrl}endowment-fund/`)
    },

    async getHighparkPage() {
        /**
         * Получение информации для страницы ИТМО Хайпарк
         */

        return API.get(`${pagesUrl}highpark/`)
    },

    async getResearchCenterPage() {
        /**
         * Получение информации для страницы "ИССЛЕДОВАТЕЛЬСКИЙ ЦЕНТР «СИЛЬНЫЙ ИСКУССТВЕННЫЙ ИНТЕЛЛЕКТ В ПРОМЫШЛЕННОСТИ»"
         */

        return API.get(`${pagesUrl}industrial-ai-center/`)
    },

    async getInnovativePartnershipPage() {
        /**
         * Получение информации для страницы Инновационно-коммуникационное партнерство.
         */

        return API.get(`${pagesUrl}innovative-communicative-partnership/`)
    },

    async getNccrPage() {
        /**
         * Получение информации для страницы Национальный центр когнитивных разработок.
         */

        return API.get(`${pagesUrl}nccr/`)
    },

    async getNcciPage() {
        /**
         * Получение информации для страницы Национальный центр квантового интернета.
         */

        return API.get(`${pagesUrl}ncqi/`)
    },

    async getPartnershipPage() {
        /**
         * Получение информации для страницы Партнерство (основная страница).
         */

        return API.get(`${pagesUrl}partnership/`)
    },

    async getProjectsExpertisePage() {
        /**
         * Получение информации для страницы Экспертиза проектов.
         */

        return API.get(`${pagesUrl}projects-expertise/`)
    },

    async getScientificPartnershipPage() {
        /**
         * Получение информации для страницы Научное парнерство.
         */

        return API.get(`${pagesUrl}scientific-partnership/`)
    },

    async getStrategicPartnershipPage() {
        /**
         * Получение информации для страницы Стратегическое партнерство.
         */

        return API.get(`${pagesUrl}strategic-partnership/`)
    },

    async getStrategicProjectsPage() {
        /**
         * Получение информации для страницы Стратегические проекты (основная страница).
         */

        return API.get(`${pagesUrl}strategic-projects/`)
    },

    async getTechnologyTransferPage() {
        /**
         * Получение информации для страницы Трансфер технологий (основная страница).
         */

        return API.get(`${pagesUrl}technology-transfer/`)
    },

    async sendRegistrationApplication(data) {
        /**
         * Отправление формы регистрации
         */

        return API.post(`${pagesUrl}project-consultation/email/`, data)
    }
}
