// eslint-disable
import { PagesAPI } from '@/api/pages';

const state = {
    registrationApplicationSent: false
}

const getters = {
    registrationApplicationSent: (s) => s.registrationApplicationSent
}

const actions = {
    async sendPartnerApplciation({ commit }, payload) {
        await PagesAPI.sendRegistrationApplication(payload)

        commit('SET_REGISTRATION_APPLICATION_SENT', true)
    },

    setRegistrationApplicationSent({ commit }, payload) {
        commit('SET_REGISTRATION_APPLICATION_SENT', payload)
    }
}

const mutations = {
    SET_REGISTRATION_APPLICATION_SENT(state, payload) {
        state.registrationApplicationSent = payload
    }
}

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
}
