import { createStore } from 'vuex'
import VuexPersistence from 'vuex-persist'
import base from "@/store/base.store";
import config from "@/store/config.store";
import widgets from "@/store/widgets.store";
import partners from "@/store/partners.store";
import products from "@/store/products.store";
import productCategories from "@/store/productCategories.store";
import header from "@/store/header.store";
import news from "@/store/news.store";
import newsCategories from "@/store/newsCategories.store";
import pages from "@/store/pages.store";
import ipExchange from "@/store/ipExchange.store";

export default createStore({
    modules: {
        base,
        config,
        widgets,
        partners,
        products,
        productCategories,
        header,
        news,
        newsCategories,
        pages,
        ipExchange
    },
    plugins: [new VuexPersistence().plugin]
})
