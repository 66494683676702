// eslint-disable
import { WidgetAPI } from '@/api/widget';

const state = {
    mainPageWidgets: null, // Виджеты на главной странице
    partnershipAgreementWidgets: null, // Виджеты для соглашения о партнерстве
    accompanimentConsultingFinancingWidgets: null, // Виджеты для табов в инновационно-коммуникационном партнерстве
    clusterDevelopmentOptionsWidgets: null, // Категории разработок для виджета "Разработки Университета инфо"
    educationalPartnershipStages: null, // Этапы на странице Образовательного партнерства
    educationalPartnershipStats: null, // Статистика на странице Образовательного партнерства
    expertiseDirectionsWidgets: null, // Текст для нумерованых виджетов на странице Научно-техничесая экспертиза
    strategicPartnershipDirectionsWidgets: null, // Виджеты на странице стратегического партнерства
    technoparkStats: null, // Статистика для технопарка
    services: { colorful: [], basic: [] }, // список услуг
}

const getters = {
    mainPageWidgets: (s) => s.mainPageWidgets,
    partnershipAgreementWidgets: (s) => s.partnershipAgreementWidgets,
    accompanimentConsultingFinancingWidgets: (s) => s.accompanimentConsultingFinancingWidgets,
    clusterDevelopmentOptionsWidgets: (s) => s.clusterDevelopmentOptionsWidgets,
    educationalPartnershipStages: (s) => s.educationalPartnershipStages,
    educationalPartnershipStats: (s) => s.educationalPartnershipStats,
    expertiseDirectionsWidgets: (s) => s.expertiseDirectionsWidgets,
    strategicPartnershipDirectionsWidgets: (s) => s.strategicPartnershipDirectionsWidgets,
    technoparkStats: (s) => s.technoparkStats,
    services: (s) => s.services,
}

const actions = {
    async getMainPageWidgets({ commit }) {
        const response = await WidgetAPI.getMainPageWidgets()

        commit('SET_MAIN_PAGE_WIDGETS', response.data)
    },

    async getPartnershipAgreementWidgets({ commit }) {
        const response = await WidgetAPI.getPartnershipAgreementWidgets()

        commit('SET_PARTNERSHIP_AGREEMENT_WIDGETS', response.data)
    },

    async getAccompanimentConsultingFinancingWidgets({ commit }) {
        const response = await WidgetAPI.getAccompanimentConsultingFinancingWidgets()

        commit('SET_ACCOMPANIMENT_CONSULTING_FINANCING_WIDGETS', response.data)
    },

    async getClusterDevelopmentOptionsWidgets({ commit }) {
        const response = await WidgetAPI.getClusterDevelopmentOptionsWidgets()

        commit('SET_CLUSTER_DEVELOPMENT_OPTIONS_WIDGETS', response.data)
    },

    async getEducationalPartnershipStages({ commit }) {
        const response = await WidgetAPI.getEducationalPartnershipStages()

        commit('SET_EDUCATIONAL_PARTNERSHIP_STAGES', response.data)
    },

    async getEducationalPartnershipStats({ commit }) {
        const response = await WidgetAPI.getEducationalPartnershipStats()

        commit('SET_EDUCATIONAL_PARTNERSHIP_STATS', response.data)
    },

    async getExpertiseDirectionsWidgets({ commit }) {
        const response = await WidgetAPI.getExpertiseDirectionsWidgets()

        commit('SET_EXPERTISE_DIRECTIONS_WIDGETS', response.data)
    },

    async getStrategicPartnershipDirectionsWidgets({ commit }) {
        const response = await WidgetAPI.getStrategicPartnershipDirectionsWidgets()

        commit('SET_STRATEGIC_PARTNERSHIP_DIRECTIONS_WIDGETS', response.data)
    },

    async getTechnoparkStats({ commit }) {
        const response = await WidgetAPI.getTechnoparkStats()

        commit('SET_TECHNOPARK_STATS', response.data)
    },

    async getServices({ commit }) {
        const responseColorful = await WidgetAPI.getColorfulServices()
        const responseBasic = await WidgetAPI.getBasicServices()

        const response = { colorful: responseColorful.data, basic: responseBasic.data }

        commit('SET_SERVICES', response)
    }
}

const mutations = {
    SET_MAIN_PAGE_WIDGETS(state, payload) {
        state.mainPageWidgets = payload
    },

    SET_PARTNERSHIP_AGREEMENT_WIDGETS(state, payload) {
        state.partnershipAgreementWidgets = payload
    },

    SET_ACCOMPANIMENT_CONSULTING_FINANCING_WIDGETS(state, payload) {
        state.accompanimentConsultingFinancingWidgets = payload
    },

    SET_CLUSTER_DEVELOPMENT_OPTIONS_WIDGETS(state, payload) {
        state.clusterDevelopmentOptionsWidgets = payload
    },

    SET_EDUCATIONAL_PARTNERSHIP_STAGES(state, payload) {
        state.educationalPartnershipStages = payload
    },

    SET_EDUCATIONAL_PARTNERSHIP_STATS(state, payload) {
        state.educationalPartnershipStats = payload
    },

    SET_EXPERTISE_DIRECTIONS_WIDGETS(state, payload) {
        state.expertiseDirectionsWidgets = payload
    },

    SET_STRATEGIC_PARTNERSHIP_DIRECTIONS_WIDGETS(state, payload) {
        state.strategicPartnershipDirectionsWidgets = payload
    },

    SET_TECHNOPARK_STATS(state, payload) {
        state.technoparkStats = payload
    },

    SET_SERVICES(state, payload) {
        state.services = payload
    },
}

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
}
