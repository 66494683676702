// eslint-disable
import { PartnersAPI } from '@/api/partners';

const state = {
    partners: [], // Список партнеров
    partnersApplicationSent: false
}

const getters = {
    partners: (s) => s.partners,
    partnersApplicationSent: (s) => s.partnersApplicationSent
}

const actions = {
    async getPartners({ commit }) {
        const response = await PartnersAPI.getPartners()

        commit('SET_PARTNERS', response.data)
    },

    async sendPartnerApplciation({ commit }, payload) {
        await PartnersAPI.sendPartnerApplciation(payload)

        commit('SET_PARTNER_APPLICATION_SENT', true)
    },

    setPartnersApplicationSent({ commit }, payload) {
        commit('SET_PARTNER_APPLICATION_SENT', payload)
    }
}

const mutations = {
    SET_PARTNERS(state, payload) {
        state.mainPageWidgets = payload
    },

    SET_PARTNER_APPLICATION_SENT(state, payload) {
        state.partnersApplicationSent = payload
    }
}

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
}
