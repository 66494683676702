/* Модуль API с запросами к партнерам */

// eslint-disable
import API from './api'

const partnersUrl = '/partners/'

export const PartnersAPI = {
    async getPartners() {
        /**
         * Получение списка партнеров
         */

        return API.get(`${partnersUrl}`)
    },

    async sendPartnerApplciation(application) {
        /**
         * Подача заявки на партнерство
         */

        return API.post(`${partnersUrl}applications/`, application)
    }

    // async getPartnersById(partnerId) {
    //     /**
    //      * Получение партнера по id
    //      */
    //
    //     return API.get(`${partnersUrl}${partnerId}/`)
    // },
}
