/* Модуль с общим состоянием приложения */

const state = {
    isMenuVisible: false, // Видимость меню
    showCookieBanner: true,
}

const getters = {
    isMenuVisible: (s) => s.isMenuVisible,
    showCookieBanner: (s) => s.showCookieBanner
}

const actions = {
    toggleMenu({ commit, state }) {
        const root = document.querySelector(':root')

        const menuColor = state.isMenuVisible ? '#fff' : 'var(--secondary-color)'
        root.style.setProperty('--header-color', menuColor);

        const borderColor = state.isMenuVisible ? 'var(--header-border-light)' : 'var(--header-border-dark)'
        root.style.setProperty('--header-border-color', borderColor);

        commit('SET_MENU_VISIBLE', !state.isMenuVisible)
    },

    toggleCookieBanner({ commit, state }) {
        commit('SHOW_COOKIE_BANNER', !state.showCookieBanner)
    }
}

const mutations = {
    SET_MENU_VISIBLE(state, payload) {
        state.isMenuVisible = payload
    },

    SHOW_COOKIE_BANNER(state, payload) {
        state.showCookieBanner = payload
    }
}

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
}
